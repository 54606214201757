import { ButtonPrimary } from 'components/Atoms/Button';
import React from 'react';
import { routes } from 'utilities/routing/generatedRoutes';

const NewProduction = () => {
  return <>
    <ButtonPrimary
      label={RESX.GeneralLabels.resxNew}
      onClick={() => window.location.href = routes.production_New().absolute}
    />
  </>;
};

export default NewProduction;