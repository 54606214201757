import * as moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";

import ReduxDefaultProviders from "./common/ReduxDefaultProviders";
import { Store } from "redux";
import { bootstrapSystemOneWithoutRedux } from "./common/bootstrapSystemOne";

import LoginApp from "authentication/LoginApp";

declare const viewModel: server.viewmodel.AccountSignInFrontModel;

jQuery(() => {
    moment.locale(site.culture.uiLocale);

    const pageComponents = (store: Store<any>) => {
        const root = createRoot(document.getElementById("contentholder"));
        root.render(
            <ReduxDefaultProviders store={store}>
                <LoginApp
                    viewModel={viewModel}
                />
            </ReduxDefaultProviders>);
    };

    bootstrapSystemOneWithoutRedux(pageComponents);
});
