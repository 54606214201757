import moment from 'moment';
import React from 'react';

interface NotificationCardProps {
  notification: server.dto.Notification;
  onRead: () => void;
  onDismiss: () => void;
}

const getTitle = (notification: server.dto.Notification) => {
  if (notification.rawTitle) {
    return notification.htmlTitle;
  }

  return notification.actor
    ? `${notification.actor.fullName} ${notification.htmlTitle}`
    : notification.htmlTitle;
}

const NotificationCard = (props: NotificationCardProps) => {
  const { notification, onRead, onDismiss } = props;

  const imageUrl = notification.avatarUrl
    ? notification.avatarUrl
    : notification.actor
      ? notification.actor.avatarUrl
      : "https://systemone-cdn.azureedge.net/images/systemoneavatar.png";

  return <a
      href={notification.notificationAction.action}
      className="notification-card-wrapper"
      target={notification.notificationAction.openInNewTab ? "_blank" : ""}
      onClick={onRead}
    >
    {!notification.read && <div className="notification-card-new" onClick={(e) => { e.preventDefault() }} />}
    <div className="notification-card-content">
      <img src={imageUrl} className="avatar-square" />
      <div className="notification-card-text">
        <div
          className="subtitle-text-medium"
          dangerouslySetInnerHTML={{ __html: getTitle(notification) }}
        />
        <div className="label-text-regular">
          {notification.message}
        </div>
        <div className="notification-card-text-time label-text-regular">
          {moment(notification.createdOn).subtract(30, "seconds").fromNow()}
        </div>
      </div>
    </div>
    <div
      className="notification-card-cancel"
      role="button"
      onClick={(e) => { e.preventDefault(); onDismiss(); }}
    >
      <i className="icon-cancel" />
    </div>
  </a>;
}

export default NotificationCard;