// The hooks are copied from https://github.com/uidotdev/usehooks/blob/main/index.js
// And slightly modified to fit our code standards and to add typings
import React from "react";

const dispatchStorageEvent = (key: string, newValue: any) => {
    window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
}

const setLocalStorageItem = (key: string, value: any) => {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
    dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
    window.localStorage.removeItem(key);
    dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string) => {
    return window.localStorage.getItem(key);
};

const useLocalStorageSubscribe = (callback: (this: Window, ev: StorageEvent) => any) => {
    window.addEventListener("storage", callback);
    return () => window.removeEventListener("storage", callback);
};

const getLocalStorageServerSnapshot = () => {
    throw Error("useLocalStorage is a client-only hook");
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const getSnapshot = () => getLocalStorageItem(key);

    const store = React.useSyncExternalStore(
        useLocalStorageSubscribe,
        getSnapshot,
        getLocalStorageServerSnapshot
    );

    const setState = React.useCallback(
        (v: React.SetStateAction<T>) => {
            try {
                const nextState = (typeof v === "function")
                    ? (v as ((prevState: T) => T))(JSON.parse(store) as T)  // Explicitly cast 'v' as a function
                    : v;

                if (nextState === undefined || nextState === null) {
                    removeLocalStorageItem(key);
                } else {
                    setLocalStorageItem(key, nextState);
                }
            } catch (e) {
                console.warn(e);
            }
        },
        [key, store]
    );

    React.useEffect(() => {
        if (
            getLocalStorageItem(key) === null &&
            typeof initialValue !== "undefined"
        ) {
            setLocalStorageItem(key, initialValue);
        }
    }, [key, initialValue]);

    return [store ? (JSON.parse(store) as T) : initialValue, setState] as const;
}

export const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export const useMediaQuery = (query: string) => {
    const subscribe = React.useCallback(
        (callback: (this: MediaQueryList, ev: MediaQueryListEvent) => any) => {
            const matchMedia = window.matchMedia(query);

            matchMedia.addEventListener("change", callback);
            return () => {
                matchMedia.removeEventListener("change", callback);
            };
        },
        [query]
    );

    const getSnapshot = () => {
        return window.matchMedia(query).matches;
    };

    const getServerSnapshot = () => {
        throw Error("useMediaQuery is a client-only hook");
    };

    return React.useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
