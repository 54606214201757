import SupportQuestionMark from 'components/SupportQuestionMark';
import React from 'react';
import { useViewport } from 'utilities/hookutils';

interface NavigationBarProps {
  selectedMenu: server.dto.MainMenuItem;
  currentPage: server.dto.RouteKeys;
  articleId?: number;
  newButton?: React.ReactNode;
}

const NavigationBar = (props: NavigationBarProps) => {
  const { selectedMenu, currentPage, articleId: supportArticleId, newButton } = props;
  const viewportType = useViewport();
  const subMenuItems = selectedMenu.items.filter(item => item.hidden !== true);

  const displayNewButtonOnFirstRow = (viewportType === 'mobile' || subMenuItems.length === 0) && newButton;
  const displayNewButtonOnSecondRow = viewportType !== 'mobile' && subMenuItems.length > 0 && newButton;

  return <div className="top-navigation-wrapper">
    <div className="top-navigation-header">
      <div className="top-navigation-title">
        <h1 className="title-text-bold">{selectedMenu.label}</h1>
        {supportArticleId && <SupportQuestionMark articleId={supportArticleId} />}
      </div>
      {displayNewButtonOnFirstRow && <div className="top-navigation-new-button">
        {newButton}
      </div>}
    </div>
    <div className="top-navigation-items-wrapper">
      {(subMenuItems.length > 0) && <div className="top-navigation-items">
        {subMenuItems.map(item =>
          <a key={item.url} href={item.url} className="top-navigation-item subtitle-text-medium">
            {item.label}
            {item.highlight.includes(currentPage) && <div className="top-navigation-select" />}
          </a>
        )}
      </div>}
      {displayNewButtonOnSecondRow && <div className="top-navigation-new-button">
        {newButton}
      </div>}
    </div>
  </div>;
};

export default NavigationBar;
