import axios from "axios";
import { createAction } from "redux-actions";

import { CALL_API } from "../../middleware/api";
import { Actions } from "../actions/actions";

//import { arrayOfNotifications } from "./schema";

axios.defaults.timeout = 30000;

// action creators

const receiveNotifications = createAction<any, any>(
    Actions.Notifications.NOTIFICATIONS_RECEIVE,
    (data: any) => data
);

const loadingNotificationsComplete = createAction(
    Actions.Notifications.NOTIFICATIONS_LOADING_COMPLETE
);

const updateNotificationBadge = createAction<number, number>(
    Actions.Notifications.NOTIFICATIONBADGE_SET,
    (data: number) => data
);

const notificationsAllSeen = createAction(
    Actions.Notifications.NOTIFICATIONS_ALLSEEN
);

const notificationsRead = createAction<number[], number[]>(
    Actions.Notifications.NOTIFICATIONS_READ,
    (notifications) => notifications
);

const notificationsAllRead = createAction(
    Actions.Notifications.NOTIFICATIONS_ALLREAD
);

const notificationsUnread = createAction<number[], number[]>(
    Actions.Notifications.NOTIFICATIONS_UNREAD,
    (notifications) => notifications
);

const notificationsHidden = createAction<number[], number[]>(
    Actions.Notifications.NOTIFICATIONS_HIDDEN,
    (notifications) => notifications
);

const hideNotifications = (notifications: number[]) => () => window.CoreApi.Notification.Hide(notifications);


const markAllNotificationsRead = () => (dispatch) => {
    dispatch(notificationsAllRead());

    return window.CoreApi.Notification.MarkAllRead("browser");
};

const markNotificationsRead = (notifications: number[], read?: boolean) => () => {

    const r = read == null ? true : read;

    return window.CoreApi.Notification.MarkRead(notifications, r);
};

const fetchNotifications = (page: number) => (dispatch) => window.CoreApi.Notification.ListNotifications({
    channel: 'browser',
    markAllSeen: true,
    page,
    perPage: 25,
}).then((response) => {
    dispatch(receiveNotifications(response.data.data));
    dispatch(loadingNotificationsComplete());

    return response.data;
})
    .catch(() => {
        //console.log(error);
    });

const fetchNotificationsForTopMenu = () => ({
    [CALL_API]: {
        endpoint: `${window.site.coreApiBaseUrl}/notification`,
        types: [
            Actions.Notifications.NOTIFICATIONS_REQUEST,
            Actions.Notifications.NOTIFICATIONS_SUCCESS,
            Actions.Notifications.NOTIFICATIONS_FAILURE
        ]
    }
});

export {
    fetchNotifications,
    fetchNotificationsForTopMenu,
    hideNotifications,
    markAllNotificationsRead,
    markNotificationsRead,
    notificationsAllSeen,
    notificationsRead,
    notificationsUnread,
    notificationsHidden,
    updateNotificationBadge,
    receiveNotifications
};
