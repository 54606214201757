import classNames from "classnames";
import React from "react";

const classes = (white?: boolean) => classNames({
    "icon-question": true,
    "s1_gray": white !== true
})

const SupportQuestionMark = ({ articleId, white }: { articleId: number, white?: boolean }) => (
    <div>
        <a
            className={classes(white)}
            onClick={(e) => {
                e.stopPropagation();
                window.OpenSupportSidebar(articleId);
            }}
        />
    </div>
);

export default SupportQuestionMark;