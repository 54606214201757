import moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import EnvironmentModal from "../authentication/environmentmodal/app";
import { enGB, enUS, de, nl, es, pt, it, ptBR } from "date-fns/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import thunk from "redux-thunk";

import api from "../middleware/api";

import {
    signalRMiddleware,
    signalRStart
} from "../notifications/index";

import bootstrapNavigation from "./bootstrapNavigation";

type PageComponents = (store: Store<any>, sageMiddleware?: SagaMiddleware<unknown>) => void

let store: Store<any>;

/**
 *
 * @param pageReducers All the reducers on the page
 * @param pageInitialState Initial redux state
 * @param pageComponents
 * @param pageSignalRMiddleware Optional custom signalR middleware for handling Redux actions through signalR.
 * @param pageSignalRStart Optional custom signalR start function
 */
export const bootstrapSystemOne = (
    pageReducers: any,
    pageInitialState: () => any,
    pageComponents?: PageComponents,
    pageSignalRMiddleware?: any,
): Store<any> => {

    let locale: Locale;
    switch (site.culture.uiLocale) {
        case "en-GB": locale = enGB; break;
        case "en-US": locale = enUS; break;
        case "nl-NL": locale = nl; break;
        case "de-DE": locale = de; break;
        case "it-IT": locale = it; break;
        case "es-ES": locale = es; break;
        case "pt-PT": locale = pt; break;
        case "pt-BR": locale = ptBR; break;
        default: locale = enGB;
    }
    registerLocale(site.culture.uiLocale, locale);
    setDefaultLocale(site.culture.uiLocale);


    moment.locale(site.culture.uiLocale);

    const reducer = pageReducers ? combineReducers(pageReducers) : (state: any) => state;

    const composeEnhancers = composeWithDevTools;

    const actualSignalRMiddleware = (pageSignalRMiddleware != null) ? pageSignalRMiddleware : signalRMiddleware;

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        thunk,
        api,
        actualSignalRMiddleware,
        sagaMiddleware
    ];

    const enhancer = composeEnhancers(
        applyMiddleware(...middleware)
    );

    const initialState = {
        ...pageInitialState()
    };

    store = createStore(reducer, initialState as any, enhancer);

    bootstrapNavigation(store);

    const environmentModalDiv = document.createElement("div");
    environmentModalDiv.id = "environment-modal";
    document.body.appendChild(environmentModalDiv);
    const environmentModalRoot = createRoot(environmentModalDiv);
    environmentModalRoot.render(<EnvironmentModal />);

    if (pageComponents) {
        pageComponents(store, sagaMiddleware);
    }

    if (site.currentUser) {
        // if (pageSignalRStart != null) {
        //     pageSignalRStart(store);
        // } else {
        signalRStart(store);
        // }
    }

    return store;
};

export const bootstrapSystemOneWithoutRendering = () => {
    return bootstrapSystemOne(undefined, () => null, () => null);
}

export const bootstrapSystemOneWithoutRedux = (pageComponents?: PageComponents) => {
    return bootstrapSystemOne(undefined, () => null, pageComponents);
}