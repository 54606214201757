import React, { useRef, useState } from 'react';
import MainNavigation from './MainNavigation';
import MoreNavigation, { MoreNavigationHandle } from './MoreNavigation';

interface SidebarNavigationProps {
  navigation: server.dto.Navigation;
  currentPage: server.dto.RouteKeys;
}

const SidebarNavigation = (props: SidebarNavigationProps) => {
  const { navigation, currentPage } = props;
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const ref = useRef<MoreNavigationHandle>(null);

  const onMore = () => {
    ref.current.onMore();
  }

  return <>
    <MainNavigation
      isMoreOpen={isMoreOpen}
      navigation={navigation}
      onMore={onMore}
    />
    <MoreNavigation
      navigation={navigation}
      isOpen={isMoreOpen}
      setIsOpen={setIsMoreOpen}
      currentPage={currentPage}
      ref={ref}
    />
  </>;
};

export default SidebarNavigation;
