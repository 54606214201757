import React from "react";
import { useCustomQuery } from "utilities/hookutils";
import BaseReactSelectDropdown from "./BaseReactSelectDropdown";
import Sublabel from "components/Atoms/Sublabel";
import { DropdownProps } from "../models";
interface Props extends DropdownProps<server.dto.BookkeepingSmall> {
    selectIfOnlyOption?: boolean;
}

const BookkeepingDropdown = (props: Props) => {
    const bookkeepingApi = useCustomQuery({
        queryKey: ["bookkeeping"],
        queryFn: () => window.CoreApi.Bookkeeping.GetBookkeepings().then(res => res.data),
        enabled: !props.options,
        onSuccess: (data) => {
            if (props.selectIfOnlyOption && !props.value && data.length === 1) {
                props.onChange(data[0]);
            }
        },
        initialData: props.options
    });

    const options = bookkeepingApi.data;

    const isSelected = (bookkeepingId: number) => {
        if(props.value == null) return false;

        if(Array.isArray(props.value)) {
            return props.value.some(v => v.bookkeepingId === bookkeepingId);
        }

        return (props.value as server.dto.Bookkeeping).bookkeepingId === bookkeepingId;
    }

    return <BaseReactSelectDropdown
        value={props.value}
        onChange={props.onChange}
        options={options}
        getLabel={bookkeeping => bookkeeping.name}
        getValue={bookkeeping => bookkeeping.bookkeepingId}
        excludeKeys={props.excludeKeys}
        renderOption={(bookkeeping) => <div>
            <div>{bookkeeping.name}</div>
            {!isSelected(bookkeeping.bookkeepingId) && <Sublabel small>{bookkeeping.code}</Sublabel>}
            {isSelected(bookkeeping.bookkeepingId) && <div className="s1_small">{bookkeeping.code}</div>}
        </div>}
        config={{
            isSearchable: true,
            placeholder: RESX.Bookkeeping.resxBookkeeping,
            ...props.config
        }}
    />
}

export default BookkeepingDropdown;