import React from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { NoDataAvailableDiv } from 'components/NoDataAvailableDiv';
import NotificationCard from './NotificationCard';
import { Loading, Error } from 'components/panels';
import { useInView } from 'react-intersection-observer';
import { ButtonPrimary, ButtonText } from 'components/Atoms/Button';
import { routes } from 'utilities/routing/generatedRoutes';
import { onSetNotificationBadge, onAddNotifications, onNotificationsHidden, onNotificationsRead, onNotificationsUnread } from 'common/signalR';

interface NotificationListProps {
  onNotificationsListOpen: () => void;
}

const NotificationList = (props: NotificationListProps) => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const {
    status,
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["notifications"],
    async ({ pageParam = 1 }) => {
      const res = await window.CoreApi.Notification.ListNotifications({
        markAllSeen: true,
        channel: "browser",
        page: pageParam,
        perPage: 25,
      })
      props.onNotificationsListOpen();
      return res.data;
    }, {
    getPreviousPageParam: (wrapper) => wrapper.pagination.page - 1,
    getNextPageParam: (wrapper) =>
      wrapper.pagination.page !== wrapper.pagination.pageCount ? wrapper.pagination.page + 1 : undefined,
  })

  React.useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  React.useEffect(() => {
    const { removeEvent } = onSetNotificationBadge(() => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent2 } = onAddNotifications(() => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent3 } = onNotificationsHidden(() => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent4 } = onNotificationsRead(() => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));
    const { removeEvent: removeEvent5 } = onNotificationsUnread(() => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }));

    return () => {
      removeEvent();
      removeEvent2();
      removeEvent3();
      removeEvent4();
      removeEvent5();
    }
  }, [])

  const hideNotification = useMutation({
    mutationFn: (notification: server.dto.Notification) => window.CoreApi.Notification.Hide([notification.notificationId]),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }),
  })

  const markRead = useMutation({
    mutationFn: (notification: server.dto.Notification) => window.CoreApi.Notification.MarkRead([notification.notificationId], true),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }),
  })

  const markAllRead = useMutation({
    mutationFn: () => window.CoreApi.Notification.MarkAllRead("browser"),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["notifications"], fetchStatus: "idle" }),
  })

  const notifications = data?.pages.map(p => p.data).flat() ?? [];
  const visibleNotifications = notifications
    .sort((a, b) => b.notificationId - a.notificationId)
    .filter(n => !n.hidden);

  return <>
    {status === 'success' && notifications.length === 0 && (<div className="notification-card-list">
      <NoDataAvailableDiv color="yellow" />
    </div>)}
    {status === 'success' && notifications.length > 0 && (<div className="notification-card-list">
      {visibleNotifications.map((notification, index) =>
        <React.Fragment key={notification.notificationId}>
          {index === visibleNotifications.length - 3 && (<div ref={ref} />)}
          <NotificationCard
            key={notification.notificationId}
            notification={notification}
            onRead={() => { if (!notification.read) { markRead.mutate(notification) } }}
            onDismiss={() => hideNotification.mutate(notification)}
          />
        </React.Fragment>
      )}
    </div>)}
    {(isLoading || isFetchingNextPage) && (<Loading />)}
    {status === "error" && (<Error />)}
    <div className="popup-menu-footer">
      <ButtonText
        label={RESX.Notifications.SeeAllNotifications}
        href={routes.notification_List().absolute}
      />
      <ButtonPrimary
        label={RESX.Notifications.MarkAllAsRead}
        onClick={() => markAllRead.mutate()}
      />
    </div>
  </>
};

export default NotificationList;
