import { ButtonPrimary } from 'components/Atoms/Button';
import AppForEsign from 'components/EsignOrLegacy/documentlist/appForEsign';
import React from 'react';
import { useCustomQuery } from 'utilities/hookutils';

const NewPurchaseAgreement = () => {
  const bookkeepingsApi = useCustomQuery({
    queryFn: () => window.CoreApi.Bookkeeping.GetBookkeepings(),
    queryKey: ["bookkeepings"],
  });

  const brandsApi = useCustomQuery({
    queryFn: () => window.CoreApi.Brand.ListBrand({ page: 1, perPage: 50 }),
    queryKey: ["brands"],
  });

  return <>
    <ButtonPrimary
      label={RESX.GeneralLabels.resxNew}
      onClick={() => (window as any).createNewDocument("")}
    />
    <AppForEsign
      documentType="purchaseAgreement"
      bookkeepings={bookkeepingsApi.data?.data ?? []}
      brands={brandsApi.data?.data ?? []}
    />
  </>
};

export default NewPurchaseAgreement;
