import BigIconButton from 'components/Atoms/BigIconButton';
import React from 'react';
import Popup from 'reactjs-popup';
import { routes } from 'utilities/routing/generatedRoutes';

const SettingsMenu = () => {
  if (!site.permissions.canConfigureSystemSettings && !site.permissions.canConfigureTemplates) {
    return null;
  };

  return <>
    <Popup
      trigger={<BigIconButton
        icon="icon-settings"
        onClick={() => {}}
      />}
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => {}}
      onClose={() => {}}
      contentStyle={{ padding: '0px', border: 'none' }}
      arrow={false}
    >
      <div className="popup-menu-wrapper">
        {site.permissions.canConfigureSystemSettings && (<a href={routes.systemSetting_Menu().absolute} className="popup-menu-item">
          <i className="icon-settings" />
          <span className="subtitle-text-semibold">{RESX.GeneralLabels.SystemSettings}</span>
        </a>)}
        {site.permissions.canConfigureTemplates && (<a href={routes.templateSetting_Menu().absolute} className="popup-menu-item">
          <i className="icon-templates" />
          <span className="subtitle-text-semibold">{RESX.Template.TemplateSettings}</span>
        </a>)}
      </div>
    </Popup>
  </>
}

export default SettingsMenu;
