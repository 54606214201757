import classnames from "classnames";
import React from "react";
import { Loader } from "semantic-ui-react";

export type ButtonTypes = "primary" | "secondary" | "tertiary" | "white" | "warning" | "text" | "back";

export interface ButtonProps {
  type: ButtonTypes;
  style?: React.CSSProperties;
  className?: string;
  label?: string;
  disabled?: boolean;
  htmlType?: "button" | "submit" | "reset";
  onClick?: (e: any) => void;
  href?: string;
  fluid?: boolean;
  wide?: boolean;
  iconLeft?: string;
  iconRight?: string;
  loading?: boolean;
}

const MyButton = (props: ButtonProps) => {
  const Comp = props.href ? "a" : "button";

  const iconOnly = !props.label && (props.iconLeft || props.iconRight);


  const classes = classnames(
    "button-new",
    props.type,
    props.className,
    {
      "disabled": props.disabled,
      "fluid": props.fluid,
      "wide": props.wide && !props.loading,
      // We can't use icon-* classnames casue they are reserved for icons
      "no-content": iconOnly,
    }
  );

  const displayRightPlaceholder = props.wide && !props.iconRight;
  const displayLeftPlaceholder = props.wide && !props.iconLeft;

  return (
    <Comp
      style={props.style}
      disabled={props.disabled}
      className={classes}
      type={props.htmlType || "button"}
      href={props.href}
      onClick={props.onClick}
    >
      {props.loading ? <Loader inline="centered" size="tiny" /> : <>
        {displayLeftPlaceholder && <span />}
        {props.iconLeft && <i className={`button-new-icon left tiny ${props.iconLeft}`} />}
        {!iconOnly && <span className="subtitle-text-medium">{props.label}</span>}
        {displayRightPlaceholder && <span />}
        {props.iconRight && <i className={`button-new-icon right tiny ${props.iconRight}`} />}
      </>}
    </Comp>
  );
}

export const Button = (props: ButtonProps) =>
  <MyButton {...props} />;

export const ButtonPrimary = (props: Omit<ButtonProps, "type">) =>
  <MyButton {...props} type="primary" />;

export const ButtonSecondary = (props: Omit<ButtonProps, "type">) =>
  <MyButton {...props} type="secondary" />;

export const ButtonTertiary = (props: Omit<ButtonProps, "type">) =>
  <MyButton {...props} type="tertiary" />;

export const ButtonWhite = (props: Omit<ButtonProps, "type">) =>
  <MyButton {...props} type="white" />;

export const ButtonWarning = (props: Omit<ButtonProps, "type">) =>
  <MyButton {...props} type="warning" />;

export const ButtonText = (props: Omit<ButtonProps, "type">) =>
  <Button {...props} type="text" />;

export const ButtonBack = (props: Omit<ButtonProps, "type" | "iconLeft">) =>
  <Button {...props} type="back" iconLeft="icon-back" />;
