import classNames from "classnames";
import React from "react";
import { Message } from "semantic-ui-react";

interface INoDataProps {
    color?: "white" | "yellow" | "green";
    error?: boolean;
    icon?: "icon-list-unordered" | "icon-notification";
}

const NoDataAvailableDiv = (props: React.PropsWithChildren<INoDataProps>) => {
    const children = props.children || RESX.GeneralLabels.NoResultsFound;

    if (props.error) {
        return <Message color="red">{children}</Message>
    }

    const wrapperClasses = classNames(
        "no-data-available gap-large flex",
        props.color,
        {
            ["ui message"]: props.error
        });

    const newIcon = (props.icon) || "icon-list-unordered";

    const iconClasses = classNames("icon-no-data-available", newIcon);

    return (<div className={wrapperClasses} style={{ alignItems: "center" }} >
        <span><i className={iconClasses} /></span>
        <span>{children}</span>
    </div >);
};

interface IWarningProps {

}

const NoDataAvailableWarning = (props: React.PropsWithChildren<IWarningProps>) => {
    const children = props.children || RESX.GeneralLabels.NoResultsFound;

    const classes = classNames(
        "no-data-available",
        "red",
        "gap-large",
        "flex");

    return (<div className={classes} style={{ alignItems: "center" }}>
        <span><i className="icon icon-exclamation-triangle" /></span>
        <span>{children}</span>
    </div>);
};

export { NoDataAvailableDiv, NoDataAvailableWarning };
