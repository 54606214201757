import classNames from 'classnames';
import React from 'react';

interface AuthPanelProps extends React.PropsWithChildren {
  additionalFooterContent?: React.ReactNode;
  mobile?: boolean;
}

const AuthPanel = (props: AuthPanelProps) => {
  return <div className={classNames("s1_main-panel small auth-panel", { "mobile": props.mobile })} >
    <div id="system-one-logo">
      <a href={site.promoSiteUrl} target="_blank" rel="noreferrer">
        <img src={`${site.legacyPageBaseUrl}/images/logo_rgb.svg`} />
      </a>
    </div>
    <div className="s1_main-panel-content">
      {props.children}
    </div>
    <div className="auth-panel-footer">
      {props.additionalFooterContent}
    </div>
  </div>;
}

export default AuthPanel;