import React from "react";

interface IProps {
    message?: string;
}

class Error extends React.PureComponent<IProps, any> {

    public render(): JSX.Element {
        const { message } = this.props;

        return (
            <div className="ui message red margin-top-big">
                {message ? message : RESX.GeneralLabels.SomethingUnexpectedHappened}
            </div>
        );
    }
}

export default Error;
