import React from "react";
import { Input } from "semantic-ui-react";
import { getNumberOfDigitsAfterDecimalPoint, stripNonNumericLeaveSeparator } from "../utilities";

export interface NumericValue {
    value: number;
    formatted: string;
}

export interface Props {
    innerRef?: React.LegacyRef<Input>;
    value: number | string;
    onChanged: (newValue: NumericValue) => void;
    decimalPoints: number;

    onEnterPressed?: (newValue: NumericValue) => void
    canBeEmpty?: boolean;
    error?: boolean;
    width?: number;
    labelContent?: string;
    canBeNegative?: boolean;
    maxLength?: number;
    defaultWhenError?: number;
    id?: string;
    minValue?: number;
    maxValue?: number;
    placeholder?: string;
}
const maxDigitsBeforeDecimal = 9;
const NumberInput = (props: Props) => {
    const formatNumber = (val: number | string): string => {
        if ((val == null || val === "") && props.canBeEmpty === true) {
            return "";
        }
        const number = Number(val);
        //let result = number.toFixed(props.decimalPoints);
        let result: string;

        if (props.decimalPoints > 2) {
            // This will format to between 2 and `props.decimalPoints` decimal places
            // Otherwise if decimalPlaces is 6, a value like "1" will be formatted to "1.000000"
            const actualDecimalPlaces = number.toString().split(".")[1]?.length ?? 0;
            result = number.toFixed(Math.max(actualDecimalPlaces, 2));
        } else {
            result = number.toFixed(props.decimalPoints);
        }

        if (site.culture.numberDecimalSeparator !== ".") {
            result = result.replace(".", site.culture.numberDecimalSeparator);
        }

        return result;
    }

    const [input, setInput] = React.useState(formatNumber(props.value));
    const [hasPasted, setHasPasted] = React.useState(false);

    React.useEffect(() => {
        setInput(formatNumber(props.value));
    }, [props.value]);

    const onPaste = () => {
        setHasPasted(true);
    }

    const parseNumber = (input: string) => {
        let parsed = input.trim() === "" ?
            NaN :
            Number(input.replace(new RegExp(`[${site.culture.numberDecimalSeparator}]`, "g"), "."));
        if (Number.isNaN(parsed)) {
            parsed = props.defaultWhenError ?? 0;
        }
        if (props.minValue != null && parsed < props.minValue) {
            parsed = props.minValue;
        }
        if (props.maxValue != null && parsed > props.maxValue) {
            parsed = props.maxValue;
        }

        return parsed;
    }

    const allowNegatives = () => !(props.canBeNegative === false); // Only disallow if it's explicitly set to false

    const onChange = (newInput: string) => {
        const containsNegativeSign = newInput[0] === "-";
        let sanitizedInput = stripNonNumericLeaveSeparator(newInput, site.culture.numberDecimalSeparator, props.decimalPoints !== 0);

        // Don't allow input to start with a decimal separator
        if (sanitizedInput[0] === site.culture.numberDecimalSeparator) {
            sanitizedInput = "0" + sanitizedInput;
        }

        // We don't simply take the substring of the whole input
        // Because we want to limit the number before the decimal, not the entire string
        const splitNumbers = sanitizedInput.split(site.culture.numberDecimalSeparator);

        if (splitNumbers[0].length > maxDigitsBeforeDecimal) {
            // Limit the length of the part before the decimal
            const trimmedNumberBeforeDecimal = splitNumbers[0].substring(0, maxDigitsBeforeDecimal);
            sanitizedInput = sanitizedInput.replace(splitNumbers[0], trimmedNumberBeforeDecimal);
        }

        // Re-add the negative sign because it gets stripped out
        if (containsNegativeSign && allowNegatives()) {
            sanitizedInput = "-" + sanitizedInput;
        }

        const digitsAfterDecimalPoint = getNumberOfDigitsAfterDecimalPoint(sanitizedInput, site.culture.numberDecimalSeparator);

        if (digitsAfterDecimalPoint > props.decimalPoints) {
            const numberOfDigitsToRemove = digitsAfterDecimalPoint - props.decimalPoints;
            sanitizedInput = sanitizedInput.substring(0, sanitizedInput.length - numberOfDigitsToRemove);
        }

        if (hasPasted) {
            setHasPasted(false);
            if (sanitizedInput === site.culture.numberDecimalSeparator || sanitizedInput === "") {
                sanitizedInput = "0";
            } else if (sanitizedInput[sanitizedInput.length - 1] === site.culture.numberDecimalSeparator) {
                sanitizedInput = sanitizedInput.substring(0, sanitizedInput.length - 1);
            }

            const parsedNumber = parseNumber(sanitizedInput);

            setInput(formatNumber(parsedNumber));
        } else {
            setInput(sanitizedInput);
        }
    }

    const onBlur = () => {
        if (props.canBeEmpty === true && input === "") {
            const newResult: NumericValue = {
                formatted: "",
                value: undefined
            };

            props.onChanged(newResult);
            return newResult;
        }

        const numericValue = parseNumber(input);
        const formatted = formatNumber(numericValue);

        const newResult: NumericValue = {
            formatted,
            value: numericValue
        };
        props.onChanged(newResult);

        setInput(formatted);

        return newResult
    }

    const onKeyDown = (evt) => {
        if (evt.key === "Enter") {
            const newResult = onBlur();
            props.onEnterPressed?.(newResult);
        }
    }

    const style = props.width != null
        ? {
            width: props.width
        }
        : undefined;

    return <Input
        id={props.id}
        ref={props.innerRef}
        onPaste={onPaste}
        value={input}
        onChange={(_, { value }) => onChange(value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        error={props.error}
        style={style}
        labelPosition={props.labelContent ? "right" : undefined}
        maxLength={props.maxLength}
        label={props.labelContent ? {
            basic: true,
            //color: "blue",
            content: <span>{props.labelContent}</span>
        } : undefined}
        placeholder={props.placeholder}
    />;
}

export default NumberInput;