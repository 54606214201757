import { useEffect, useState } from "react";
import { onSetNotificationBadge, onAddNotifications } from 'common/signalR';

export const useNotificationCount = (initialBadge: number) => {
  const [badge, setBadge] = useState(initialBadge);

  useEffect(() => {
    const { removeEvent } = onSetNotificationBadge((newBadge: number) => {
      setBadge(newBadge);
    });
    const { removeEvent: removeEvent2 } = onAddNotifications(() => {
      setBadge(badge + 1);
    });
    return () => {
      removeEvent();
      removeEvent2();
    }
  }, [badge]);

  const onNotificationsListOpen = () => {
    setBadge(0);
  };

  return { badge, onNotificationsListOpen };
}
