import classNames from 'classnames';
import BigIconButton from 'components/Atoms/BigIconButton';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useOnClickOutside, useViewport } from 'utilities/hookutils';
import { isViewport } from 'utilities/viewportUtils';

interface MoreNavigationProps {
  navigation: server.dto.Navigation;
  currentPage: server.dto.RouteKeys;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export interface MoreNavigationHandle {
  onMore: () => void;
}

const MoreNavigation = forwardRef<MoreNavigationHandle, MoreNavigationProps>((props, ref) => {
  const { navigation, currentPage, isOpen, setIsOpen } = props;

  const viewportType = useViewport();
  const [closingShowMore, setClosingShowMore] = useState(false);
  const [openingShowMore, setOpeningShowMore] = useState(false);

  const moreRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(moreRef, () => {
    if (isOpen) {
      close();
    }
  });

  useImperativeHandle(ref, () => ({
    onMore: () => {
      if (isOpen) {
        close();
      } else {
        open();
      }
    }
  }), [isOpen]);

  const open = () => {
    if (closingShowMore || openingShowMore) {
      return;
    }

    setIsOpen(true);
    setOpeningShowMore(true);
    setTimeout(() => {
      setOpeningShowMore(false);
    }, 300);
  };

  const close = () => {
    if (closingShowMore || openingShowMore) {
      return;
    }

    setClosingShowMore(true);
    setTimeout(() => {
      setClosingShowMore(false);
      setIsOpen(false);
    }, 300);
  }

  return <div className="sidebar-navigation-more-animation-wrapper" style={{ display: isOpen ? '' : 'none' }}>
    <div ref={moreRef} className={
      classNames("sidebar-navigation-more-wrapper", {
        "closing": closingShowMore,
        "opening": openingShowMore,
        "hidden": !isOpen,
      })}>
      <div className="sidebar-navigation-more-container">
        {isViewport(viewportType).smallerThan("desktopMedium") && <div>
          <BigIconButton size="medium" icon="icon-back" onClick={close} />
        </div>}
        {navigation.moreMenu.map((item) =>
          <div key={item.label}>
            <div className="sidebar-navigation-more-title info-text-regular">{item.label}</div>
            {item.items.map((subItem) =>
              <a key={subItem.url} href={subItem.url}
                className={classNames("sidebar-navigation-more-item", { "active-menu": subItem.highlight.includes(currentPage) })}
              >
                <div>
                  <span className="sidebar-navigation-more-item-dot" />
                  <span className="sidebar-navigation-more-item-title subtitle-text-semibold">{subItem.label}</span>
                </div>
                <span className="sidebar-navigation-more-item-subtitle body-text-regular">{subItem.subLabel}</span>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
});

export default MoreNavigation;