import React from "react";
import BaseReactSelectDropdown from "./BaseReactSelectDropdown";
import { useGetBrandsApi } from "brand/api";
import { DropdownProps } from "../models";


const BrandDropdown = (props: DropdownProps<server.dto.BrandSmall>) => {
    const brandApi = useGetBrandsApi({ enabled: !props.options })

    const options = props.options ?? brandApi.data ?? [];

    return <BaseReactSelectDropdown
        value={props.value}
        onChange={props.onChange}
        options={options}
        getLabel={brand => brand.name}
        getValue={brand => brand.brandId}
        excludeKeys={props.excludeKeys}
        config={{
            isSearchable: false,
            placeholder: RESX.Branding.resxBranding,
            ...props.config
        }}
    />
}

export default BrandDropdown;