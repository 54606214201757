import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useCustomQuery } from "utilities/hookutils";
import debounce from "lodash/debounce";

const brandQueryKey = "brands";

type BrandsApi = {
    keyword?: string;
    enabled?: boolean;
}
export const useGetBrandsApi = (config?: BrandsApi) => {
    return useCustomQuery({
        queryKey: [brandQueryKey],
        enabled: config?.enabled ?? true,
        queryFn: () => window.CoreApi.Brand.ListBrand({
            page: 1,
            perPage: 50,
            keyword: config?.keyword,
        }).then(res => res.data)
    });
}

export const useDeleteBrandApi = () => {
    const queryClient = useQueryClient();
    return useMutation(window.CoreApi.Brand.DeleteBrand, {
        onSuccess: () => {
            queryClient.invalidateQueries([brandQueryKey]);
        }
    })
};

export const useGetBrandApi = (initialValue: server.dto.Brand) => {
    return useCustomQuery({
        queryKey: [brandQueryKey, initialValue.brandId],
        queryFn: () => window.CoreApi.Brand.GetBrand(initialValue.brandId).then(r => r.data),
        initialData: initialValue,
    });
}

const clearBrandCache = (qc: QueryClient) => () => {
    qc.invalidateQueries([brandQueryKey]);
}

export const useChangeBrandNameApi = () => {
    return useMutation({
        mutationFn: window.CoreApi.Brand.SetName
    });
}

export const useChangeBrandHandleApi = () => {
    return useMutation({
        mutationFn: window.CoreApi.Brand.SetFriendlyId,
    });
}

export const useChangeBrandWebsiteApi = () => {
    return useMutation({
        mutationFn: window.CoreApi.Brand.SetWebsite,
    });
}

export const useChangeBrandEsignColorApi = () => {
    const debouncedApiFunc = React.useCallback(debounce(window.CoreApi.Brand.SetESignBackgroundFooterColor, 800), []);
    return useMutation({
        mutationFn: debouncedApiFunc
    });
}

export const useMakeDefaultBrandApi = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: window.CoreApi.Brand.SetAsDefaultForClient,
        onSuccess: clearBrandCache(queryClient),
    });
}