import { ButtonPrimary } from 'components/Atoms/Button';
import React, { useEffect, useState } from 'react';
import { Config, newAppointment } from 'utilities/coordinatorUtils';

const NewPlanning = () => {
  const [config, setConfig] = useState<Partial<Config>>({ date: null, artist: undefined });

  useEffect(() => {
    window.setPlanningConfig = (newPlanningConfig: Partial<Config>) => {
      setConfig(newPlanningConfig);
    };
  }, []);

  return <ButtonPrimary
    label={RESX.GeneralLabels.resxNew}
    onClick={() => newAppointment(config)}
  />
};

export default NewPlanning;
