import React from "react";
import { Button, Message } from "semantic-ui-react";
import BrowserCheckAlert from "../../browser/BrowserCheckAlert";
import { SocialLoginButtons } from "../../components/socialloginbuttons";
import { forgotPassword } from "./components/links";

interface Props {
    returnUrl: string;
    onLogin: (ev: React.FormEvent<HTMLFormElement>, username: string, password: string) => void;
    errorMessage?: string;
    errorDescription?: string;
    browser: server.dto.BrowserCheckOutcome
    isLoggingIn: boolean;
}

const LoginPanelContent = (props: Props) => {
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const { browser } = props;

    const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        props.onLogin(e, username, password);
    }

    // We don't want to show social logins if this page is loaded in an iframe
    // Because in an iframe, these login buttons won't work at all anyway
    const isInIframe = window.self !== window.top;

    return <>
        <div id="system-one-is-essential-software">
            <h2 className="margin-bottom-small info-text-bold">
                Essential software for artists booking agencies.
            </h2>
            <div className="body-text-regular">
                10+ years on the market and trusted by thousands of agents and artists worldwide.
            </div>
        </div>

        <BrowserCheckAlert browser={browser} />

        {props.errorMessage &&
            <Message color="red">
                <div className="flex align-center">
                    <div className="margin-right">
                        <i className="icon-exclamation-triangle" />
                    </div>
                    <div className="grow1">
                        <div>{props.errorMessage}</div>
                        {props.errorDescription && <div className="s1_small s1_gray margin-top-small">{props.errorDescription}</div>}
                    </div>
                </div>
            </Message>
        }

        <form method="post" onSubmit={onFormSubmit}>
            <div className="margin-bottom-large">
                <input
                    onChange={e => setUsername(e.target.value)}
                    type="text"
                    placeholder="Username"
                    name="username"
                    autoComplete="username"
                    autoCapitalize="none" />
            </div>

            <div className="margin-bottom-larger">
                <input
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    autoComplete="current-password"
                    placeholder="Password"
                    name="password" />
                {forgotPassword}
            </div>

            <div>
                <Button
                    type="submit"
                    primary
                    name="login"
                    fluid
                    className="button-new"
                    loading={props.isLoggingIn}
                    disabled={props.isLoggingIn}
                >
                    Sign in with System One
                </Button>
            </div>
        </form>

        {!isInIframe && <>
            <div className="text-divider info-text-medium">{RESX.GeneralLabels.Or}</div>
            <SocialLoginButtons
                isLoggingIn={props.isLoggingIn}
                returnUrl={props.returnUrl}
            />
        </>}
    </>;
};

export default LoginPanelContent;