import React from 'react';
import FaqMenu from './FaqMenu';
import SettingsMenu from './SettingsMenu';
import NotificationMenu from './NotificationMenu';
import ProfileMenu from './ProfileMenu';
import { useViewport } from 'utilities/hookutils';
import BigIconButton from 'components/Atoms/BigIconButton';
import { isViewport } from 'utilities/viewportUtils';

interface TopBarProps {
  notificationCount: number;
  currentUser: server.dto.UserSmall;
  environmentType: server.dto.Environments;
  onMenuOpen: () => void;
}

const TopBar = (props: TopBarProps) => {
  const viewportType = useViewport();

  const isSmallScreen = isViewport(viewportType).smallerThan("desktopMedium");

  return <>
    {isSmallScreen && <div className="top-bar-wrapper-palceholder" />}
    <div className="top-bar-wrapper">
      {isSmallScreen && <BigIconButton
        icon="icon-menu"
        onClick={props.onMenuOpen}
      />}
      <span className="top-bar-middle" />
      {props.environmentType === 'internal' && <FaqMenu />}
      <NotificationMenu notificationCount={props.notificationCount} />
      <SettingsMenu />
      <ProfileMenu currentUser={props.currentUser} />
    </div>
  </>;
};

export default TopBar;
