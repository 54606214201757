import classNames from "classnames";
import React from "react";
import { useViewport } from "utilities/hookutils";

interface RightSideNavigationProps {
    title: string;
    menuItems: server.dto.OptionMenuGroup[];
    currentPage: server.dto.RouteKeys;
    highlightActiveRoute?: boolean;
}

const RightSideNavigation = ({ title, menuItems, currentPage, highlightActiveRoute }: RightSideNavigationProps) => {
    const viewportType = useViewport();

    return (viewportType === "desktopWide") && <nav className="margin-left-bigger">
        <div className="settings-navigation-wrapper">
            <h3 className="nav-text-semibold">{title}</h3>
            {menuItems.map((item) => (
                <div key={item.label}>
                    <p className="info-text-semibold settings-navigation-subtitle">{item.label}</p>
                    <div className="settings-navigation-list">
                        {item.items.map(subItem => (
                            <a key={subItem.url} href={subItem.url} className={classNames("settings-navigation-link subtitle-text-medium", {
                                "active": highlightActiveRoute && (subItem.routeKey === currentPage)
                            })}>
                                {subItem.label}
                            </a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    </nav>
};

export default RightSideNavigation;
