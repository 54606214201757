import classNames from "classnames";
import React from "react";

const Sublabel = (props: React.PropsWithChildren<{ small?: boolean }>) => {
    const classes = classNames("s1_gray", {
        s1_small: props.small
    });

    return <div className={classes}>
        {props.children}
    </div>
}

export default Sublabel;