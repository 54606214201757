import React from "react";
import ModernOrLegacyForDocumentList from "./app";

interface Props {
    bookkeepings: server.dto.BookkeepingSmall[];
    brands: server.dto.BrandSmall[];
}

const AppForOnlineInvoice = (props: Props) => {
    let onlineInvoiceEnabled: "legacy" | "modern" | "legacyAndModern";
    switch (site.currentEnvironment.invoice.enabled) {
        case "onlyLegacy": onlineInvoiceEnabled = "legacy"; break;
        case "onlyOnlineInvoice": onlineInvoiceEnabled = "modern"; break;
        case "legacyAndOnlineInvoice": onlineInvoiceEnabled = "legacyAndModern"; break;
    }

    return <ModernOrLegacyForDocumentList
        documentType={"invoice"}
        bookkeepings={props.bookkeepings}
        brands={props.brands}
        modernLabel={"Online invoice"}
        legacyLabel={RESX.ESign.ClassicWorkflow}
        enabled={onlineInvoiceEnabled}

        create={(bookkeepingId, brandId) => window.CoreApi.OnlineInvoice.Create({
            bookkeepingId,
            brandId,
        })}
    />
}

export default AppForOnlineInvoice;