export const padEndOfString = (str: string, valueTobeAppendend: string, amountOfTimes: number, separator?: string): string => {
    let result = str;

    if (separator != null && !result.includes(separator)) {
        result = result.concat(separator);
    }

    const sufix = valueTobeAppendend.repeat(amountOfTimes);

    return result.concat(sufix);
};

export const stripNonNumericLeaveSeparator = (str: string, separator: string, allowDecimalSeparator?: boolean): string => {

    const regexString = `[^0-9${separator}]`;

    const regex = new RegExp(regexString, "g");

    let result = str.replace(regex, "");

    // Remove all except the last decimal point
    const allSeparatorRegexp = new RegExp(`[${separator}]`, "g");
    const singleSeparator = new RegExp(`[${separator}]`);
    while (result.match(allSeparatorRegexp)?.length > 1) {
        result = result.replace(singleSeparator, "");
    }

    if (allowDecimalSeparator === false) {
        const separatorRegex = new RegExp("\\" + site.culture.numberDecimalSeparator);
        result = result.replace(separatorRegex, "");
    }

    return result;
};

export const removeUnallowedAmountOfCharactersAfterDecimalSeparator = (str: string, numberOfCharactersAllowedAfterSeparator: number, separator: string): string => {
    let result = str;

    if (separator !== "") {

        const index = result.indexOf(separator);
        const stringHasSeparator = index !== -1;

        if (stringHasSeparator) {
            result = numberOfCharactersAllowedAfterSeparator === 0 ?
                result.substr(0, index) :
                result.substr(0, index + numberOfCharactersAllowedAfterSeparator + 1);
        }
    }

    return result;
};

export const toLowerCaseFirstChar = (str: string) => {
    if (str == null) return str;
    return str.substr(0, 1).toLowerCase() + str.substr(1);
}

// Turns an array of strings into a joined string with a cap
// E.g.: ["a", "b", "c", "d"] with a cap of 2 and a separator of ", " 
// it will return "a, b (+2)"
export const joinWithMaxLength = (values: string[], separator: string, maxLength: number): string => {
    let result = values.slice(0, maxLength).join(separator);

    if (values.length > maxLength) {
        const remaining = values.length - maxLength;
        result = result += ` (+${remaining})`;
    }

    return result;
}
