import { useEffect } from 'react';

export type ShortcutEvent = {
  readonly type: 'key-[';
}

const handleKeyDown = (e: KeyboardEvent) => {
  const target = e.target as HTMLElement;
  if (!target.isContentEditable
    && target.tagName !== "INPUT"
    && target.tagName !== "TEXTAREA"
    && target.tagName !== "SELECT"
  ) {
    if (e.key === '[') {
      const event: ShortcutEvent = { type: 'key-[' };
      document.dispatchEvent(new CustomEvent(event.type, { detail: event }));
    }
  }
};

document.addEventListener('keydown', handleKeyDown);

export const useShortcut = (key: ShortcutEvent['type'], callback: (e: ShortcutEvent) => void, deps: any[] = []) => {
  useEffect(() => {
    const handleCustomEvent = (e: Event) => {
      callback(e as ShortcutEvent);
    };
    document.addEventListener(key, handleCustomEvent);
    return () => {
      document.removeEventListener(key, handleCustomEvent);
    };
  }, deps);
};
