import React from "react";
import SelectBookkeepingAndBrand from "./SelectBookkeepingAndBrand";
import { routes } from "utilities/routing/generatedRoutes";
import ModernOrLegacyModal from "../ModernOrLegacyModal";
import { AxiosPromise } from "axios";

interface Props {
    documentType: Exclude<server.dto.DocumentTypes, "unknown">;
    bookkeepings: server.dto.BookkeepingSmall[];
    brands: server.dto.BrandSmall[];
    modernLabel: string;
    legacyLabel: string;
    enabled: "legacy" | "modern" | "legacyAndModern";

    create: (bookkeepingId: number, brandId: number, documentType: server.dto.DocumentTypes) => AxiosPromise<string>;
}

const ModernOrLegacyForDocumentList = (props: Props) => {
    const [screen, setScreen] = React.useState<"chooseLegacyOrModern" | "chooseModernSettings">(undefined);
    // Extra QS params to be added to the URL when creating a new document
    const [qs, setQs] = React.useState<string>(undefined);

    React.useEffect(() => {
        (window as any).createNewDocument = (qs) => {
            setQs(qs);

            if (props.enabled === "legacy") {
                window.location.href = getNewLegacyDocumentPageUrl(qs);
            }
            else if (props.enabled === "legacyAndModern") {
                setScreen("chooseLegacyOrModern");
            }
            else if (props.enabled === "modern") {
                onModernSelected()
            }
        }
    }, []);

    const getNewLegacyDocumentPageUrl = (qsParams: string) => {
        const qs = qsParams ? `?${qsParams}` : "";
        const documentNewRoute: keyof typeof routes = `${props.documentType}_New`;
        return routes[documentNewRoute]().absolute + qs;
    }

    const onModernSelected = () => {
        const hasOneBrand = props.brands.length === 1;
        const hasOneBookkeeping = props.bookkeepings.length === 1;
        const canSkipSelection = hasOneBrand && hasOneBookkeeping;

        if (canSkipSelection) {
            createDocument(props.bookkeepings[0].bookkeepingId, props.brands[0].brandId);
        } else {
            setScreen("chooseModernSettings");
        }
    }

    const createDocument = async (bookkeepingId: number, brandId: number) => {
        const res = await props.create(bookkeepingId, brandId, props.documentType);

        window.location.href = res.data;
    }

    const onChooseModernOrLegacy = (type: "legacy" | "modern", queryString: string) => {
        if (type === "legacy")
            window.location.href = getNewLegacyDocumentPageUrl(queryString);
        else
            onModernSelected();
    }

    if (screen === "chooseLegacyOrModern") {
        return <ModernOrLegacyModal
            documentType={props.documentType}
            onClose={() => setScreen(undefined)}
            onChoose={(val) => onChooseModernOrLegacy(val, qs)}
            modernLabel={props.modernLabel}
            legacyLabel={props.legacyLabel}
        />
    } else if (screen === "chooseModernSettings") {
        return <SelectBookkeepingAndBrand
            documentType={props.documentType}
            bookkeepings={props.bookkeepings}
            brands={props.brands}
            onClose={() => setScreen(undefined)}
            onSelected={createDocument}
        />
    }
    return <></>
}


export default ModernOrLegacyForDocumentList;