import React from "react";
import ReactSelect, { GroupBase } from "react-select";
import { CommonReactSelectWrapperProps } from "../models";
import { useReactSelectDropdown } from "../hooks";

interface ReactSelectWrapperProps<TOption, IsMulti extends boolean = false, TId = number> extends CommonReactSelectWrapperProps<TOption, IsMulti, TId> {
    options: TOption[] | Array<GroupBase<TOption>>;
}

const BaseReactSelectDropdown = <TOption, IsMulti extends boolean = false, TId = number>(props: ReactSelectWrapperProps<TOption, IsMulti, TId>) => {
    const reactSelect = useReactSelectDropdown(props);

    let options = props.options;
    if(props.excludeKeys != null) {
        if(options[0]?.hasOwnProperty("options") && options[0]?.hasOwnProperty("label")) {
            throw new Error("Cannot use exclude keys with grouped options");
        }
        const set = new Set(props.excludeKeys);
        options = (props.options as TOption[]).filter(o => !set.has(props.getValue(o)));
    }

    return <ReactSelect
        {...reactSelect.reactSelectProps}
        ref={reactSelect.ref}
        options={options}
    />
}

export default BaseReactSelectDropdown;