/**
 * Get the page numbers that should be displayed in the pagination component
 * It dynamically adjusts the range of page numbers to display based on the current page
 * and the total number of pages available, ensuring the current page is centered within the pagination display.
 * 
 * @param activePage The current active page
 * @param pageCount Total number of pages
 * @param pagesBeforeAndAfterCenterPoint How many pages appear before and after the center point. e.g. `[1, 2, 3, 4, 5, 6, 7]`: `4` is the center point and `3` is the number of pages before and after the center point
 * @returns An array of page numbers that should be displayed. Like `[4, 5, 6, 7, 8, 9, 10]`
 */
export const getPageNumbers = (activePage: number, pageCount: number, pagesBeforeAndAfterCenterPoint: number): number[] => {
    // No pagination is needed if there's only one page
    if (pageCount <= 1) {
        return [];
    }

    const maxNumberOfPagesToShow = (pagesBeforeAndAfterCenterPoint * 2) + 1

    // If active page is higher than the left edge, it means some pages on the left will be hidden. i.e. active page is not near the beginning
    const leftEdge = pagesBeforeAndAfterCenterPoint + 1;

    // If active page is lower than the right edge, it means some pages on the right will be hidden. i.e. active page is not near the end
    const rightEdge = pageCount - (pagesBeforeAndAfterCenterPoint + 1);

    const pageNumbers: number[] = [];

    let pageRangeStart = Math.max(1, activePage - pagesBeforeAndAfterCenterPoint);
    let pageRangeEnd = Math.min(pageCount, activePage + pagesBeforeAndAfterCenterPoint);

    // If the active page is near the beginning, our range will be smaller as our active page isn't centered
    // To fix the smaller range, we need to show more pages on the right side
    if (activePage <= leftEdge) {
        pageRangeEnd = Math.min(pageCount, maxNumberOfPagesToShow);
    }

    // If the active page is near the end, our range will be smaller as our active page isn't centered
    // To fix the smaller range, we need to show more pages on the left side
    if (activePage > rightEdge) {
        pageRangeStart = Math.max(1, pageCount - (maxNumberOfPagesToShow - 1));
    }

    for (let i = pageRangeStart; i <= pageRangeEnd; i++) {
        pageNumbers.push(i);
    }

    return pageNumbers;
};