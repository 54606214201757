import { ButtonPrimary } from 'components/Atoms/Button';
import NewAddressBookModal from 'components/NewAddressBookModal/NewAddressBookModal';
import React from 'react';

const NewAddressBook = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return <>
    <ButtonPrimary
      label={RESX.GeneralLabels.resxNew}
      onClick={() => setIsOpen(true)}
    />
    <NewAddressBookModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    />
  </>;
};

export default NewAddressBook;