
export const validateNumericValueAllowSeparators = (value: string, separator: string, valueCanBeNegative: boolean): boolean => {

    let regexString;

    if (valueCanBeNegative) {
        regexString = `^-?[0-9${separator}]+$`;
    } else {
        regexString = `^[0-9${separator}]+$`;
    }

    const regex = new RegExp(regexString);

    return regex.test(value);
};

export const getNumberOfDigitsAfterDecimalPoint = (value: string, decimalSeparator: string): number => {
    let result = 0;

    const splitAfterSeparator = value.split(decimalSeparator)[1];
    result = splitAfterSeparator ? splitAfterSeparator.length : 0;

    return result;
};
