import BigIconButton from 'components/Atoms/BigIconButton';
import React from 'react';
import Popup from 'reactjs-popup';

interface FaqMenuProps {}

const FaqMenu = (props: FaqMenuProps) => {
  return <>
    <Popup
      trigger={<BigIconButton
        icon="icon-question"
        onClick={() => {}}
      />}
      position="bottom right"
      closeOnDocumentClick
      onOpen={() => {}}
      onClose={() => {}}
      contentStyle={{ padding: '0px', border: 'none' }}
      arrow={false}
    >
      <div className="popup-menu-wrapper">
        <a href="https://systemonesoftware.com/en/support" className="popup-menu-item">
          <i className="icon-external-link" />
          <span className="subtitle-text-semibold">Support Articles</span>
        </a>
        <a href="https://systemonesoftware.com/en/blog" className="popup-menu-item">
          <i className="icon-external-link" />
          <span className="subtitle-text-semibold">Blog</span>
        </a>
        <a href="https://www.systemonesoftware.com/en/changelog" className="popup-menu-item">
          <i className="icon-external-link" />
          <span className="subtitle-text-semibold">Change log</span>
        </a>
      </div>
    </Popup>
  </>
}

export default FaqMenu;
