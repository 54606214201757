import React from "react";
import { routes } from "utilities/routing/generatedRoutes";

export const homepage = <a href={site.promoSiteUrl} className="body-text-regular login-additional-button" target="_blank" rel="noreferrer">Homepage</a>;

export const forgotPassword = <a href={routes.account_ResetMyPassword().absolute} className="body-text-regular login-forgot-password">Forgot password?</a>;

export const needHelp = <a href={`${site.promoSiteUrl}/support/article/541`} className="body-text-regular login-additional-button">Need help?</a>;

export const loginPage = <a href={routes.account_SignIn().absolute} className="body-text-regular login-additional-button">Login page</a>;
