import React from "react";
import { Message } from "semantic-ui-react";

const WarningMessage = (props: { title: string, message: string, panel: boolean }) => {
    const helpArticleUrl = `${site.promoSiteUrl}/support/article/${serverReference.supportArticleIds.requirements}`;

    return (<div>
        <Message color="red">
            <div className="padding flex">
                <div className="grow1">
                    <h2>{props.title}</h2>
                    <div className="margin-top">{props.message}</div>
                </div>
                <div>
                    <a className="icon-question" href={helpArticleUrl} target="_blank" rel="noreferrer" />
                </div>
            </div>
        </Message></div>);
}

const BrowserCheckAlert = (props: { browser: server.dto.BrowserCheckOutcome, panel?: boolean; }) => {
    const { browser, panel } = props;

    if (!browser) return (<></>);

    if (browser.status === "outOfDate") {
        const detectedBrowser = `${browser.detected.family.name} ${browser.detected.version}`;
        const outOfDateMessage = RESX.Browser.BrowserOutOfDateMessage.replace("{0}", detectedBrowser);
        return <WarningMessage title={RESX.Browser.BrowserOutOfDate} message={outOfDateMessage} panel={panel} />
    }

    if (browser.status === "familyNotSupported") {
        const notSupportedMessage = RESX.Browser.BrowserNotSupportedMessage.replace("{0}", browser.detected.family.name);
        return <WarningMessage title={RESX.Browser.BrowserNotSupported} message={notSupportedMessage} panel={panel} />
    }

    return (<></>)

    return <WarningMessage title={`${browser.detected.family.name} v${browser.detected.version} detected`} message="Your browser is suitable" panel={panel} />
}

export default BrowserCheckAlert;